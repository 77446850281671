import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
  FunnelIcon,
  StarIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/vue/24/outline";
import { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import { FeatureFlag, Provider } from "./shared";
import { AppUserRole } from "./AppUser";

export type OnboardingCategory = {
  id: OnboardingCategoryId;
  title: string;
  description: string;
  images?: string[];
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
  featureFlag?: FeatureFlag;
  minRole?: AppUserRole;
  dataCy?: string;
};

export enum OnboardingCategoryId {
  ConnectAccounts = "ConnectAccounts",
  SetupMetrics = "SetupMetrics",
  AssignUsers = "AssignUsers",
  InviteTeam = "InviteTeam",
  BookOnboarding = "BookOnboarding",
  JoinSlackChannel = "JoinSlackChannel",
}

export type OnboardingTask = {
  id: ClientOnboardingTaskId | WorkspaceOnboardingTaskId;
  title: string;
  description: string;
  categoryId: OnboardingCategoryId;
  importance: number;
  actionName: string;
  featureFlag?: FeatureFlag;
  minRole?: AppUserRole;
  images?: string[];
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
  dataCy?: string;
};

export enum ClientOnboardingTaskId {
  ConnectMeta = "ConnectMeta",
  ConnectTikTok = "ConnectTikTok",
  ConnectGoogle = "ConnectGoogle",
  ConnectThirdParty = "ConnectThirdParty",
  SelectMainPlatform = "SelectMainPlatform",
  SetupDefaultMetrics = "SetupDefaultMetrics",
  SetupTargetMetrics = "SetupTargetMetrics",
  SetupGlobalFilters = "SetupGlobalFilters",
  AssignUsers = "AssignUsers",
}

export enum WorkspaceOnboardingTaskId {
  InviteTeam = "InviteTeam",
  BookOnboarding = "BookOnboarding",
  JoinChannel = "JoinChannel",
}

export const ONBOARDING_CATEGORIES: OnboardingCategory[] = [
  {
    id: OnboardingCategoryId.InviteTeam,
    title: "Invite Team",
    description:
      "Invite your team members to join this workspace and collaborate on projects.",
    icon: UserGroupIcon,
    minRole: AppUserRole.ADMIN,
  },
  {
    id: OnboardingCategoryId.BookOnboarding,
    title: "Free Strategy Session",
    description:
      "Book a free strategy session with Max, one of our founders, and discover the key steps to set yourself up for long-term success with DatAds.",
    icon: CalendarDaysIcon,
    minRole: AppUserRole.ADMIN,
  },
  {
    id: OnboardingCategoryId.JoinSlackChannel,
    title: "Join Slack Channel",
    description: "Get access to a private slack channel for premium support.",
    images: ["/img/slack.png"],
    featureFlag: "support-slack-channel_2025-01-17",
    minRole: AppUserRole.ADMIN,
  },
  {
    id: OnboardingCategoryId.ConnectAccounts,
    title: "Connect Ad Platforms",
    description:
      "Connect your Meta, TikTok, and Google ad accounts. You can also optionally integrate third-party attribution tools such as Adtribute, TripleWhale, Hyros, and Tracify.",
    images: [
      "/img/triplewhale.png",
      "/img/google.png",
      "/img/tiktok.png",
      "/img/meta.png",
    ],
    dataCy: "onboarding-connect-accounts",
  },
  {
    id: OnboardingCategoryId.SetupMetrics,
    title: "Define Targets & Default Metrics",
    description:
      "Save time on manual work by defining targets and default metrics, making DatAds even more efficient.",
    icon: AdjustmentsHorizontalIcon,
  },
  {
    id: OnboardingCategoryId.AssignUsers,
    title: "Assign Users to Client",
    description:
      "Assign all team members who should have access to this client.",
    icon: UsersIcon,
    minRole: AppUserRole.ADMIN,
  },
];

export const ONBOARDING_TASKS: OnboardingTask[] = [
  {
    id: ClientOnboardingTaskId.ConnectMeta,
    title: "Connect Meta",
    description:
      "Allows you to analyze your Meta Creatives directly within DatAds.",
    categoryId: OnboardingCategoryId.ConnectAccounts,
    importance: 4,
    actionName: "Connect Meta",
    images: ["/img/meta.png"],
    minRole: AppUserRole.ADMIN,
    dataCy: "onboarding-connect-meta",
  },
  {
    id: ClientOnboardingTaskId.ConnectTikTok,
    title: "Connect TikTok",
    description:
      "Allows you to analyze your TikTok Creatives directly within DatAds.",
    categoryId: OnboardingCategoryId.ConnectAccounts,
    importance: 4,
    actionName: "Connect TikTok",
    images: ["/img/tiktok.png"],
    minRole: AppUserRole.ADMIN,
  },
  {
    id: ClientOnboardingTaskId.ConnectGoogle,
    title: "Connect Google",
    description:
      "Allows you to analyze your YouTube and Demand Gen Creatives directly within DatAds.",
    categoryId: OnboardingCategoryId.ConnectAccounts,
    importance: 4,
    actionName: "Connect Google",
    images: ["/img/google.png"],
    minRole: AppUserRole.ADMIN,
  },
  {
    id: ClientOnboardingTaskId.ConnectThirdParty,
    title: "Connect 3rd-Party Attribution Platform",
    description:
      "Allows you to see the Data from Platforms like: adtribute, TripleWhale, Hyros and Tracify.",
    categoryId: OnboardingCategoryId.ConnectAccounts,
    importance: 2,
    actionName: "Connect attribution platform",
    images: ["/img/tracify.png", "/img/triplewhale.png", "/img/adtribute.jpeg"],
    featureFlag: "adtribute_2024-05-23",
    minRole: AppUserRole.ADMIN,
  },
  {
    id: ClientOnboardingTaskId.SelectMainPlatform,
    title: "Select Main Ad Platform",
    description: "This will be the default platform for all of your reports.",
    categoryId: OnboardingCategoryId.SetupMetrics,
    importance: 2,
    actionName: "Setup Main Platform",
    images: ["/img/google.png", "/img/tiktok.png", "/img/meta.png"],
    minRole: AppUserRole.VIEWEDIT,
  },
  {
    id: ClientOnboardingTaskId.SetupDefaultMetrics,
    title: "Setup Default Metrics",
    description:
      "Save time by pre-selecting your most important metrics for any new report you create.",
    categoryId: OnboardingCategoryId.SetupMetrics,
    importance: 4,
    actionName: "Setup Default Metrics",
    icon: AdjustmentsHorizontalIcon,
    minRole: AppUserRole.VIEWEDIT,
  },
  {
    id: ClientOnboardingTaskId.SetupTargetMetrics,
    title: "Define your Targets",
    description:
      "Select metrics such as ROAS, CPL, or CAC, and set targets you aim to achieve.",
    categoryId: OnboardingCategoryId.SetupMetrics,
    importance: 2,
    actionName: "Setup Target Metrics",
    icon: StarIcon,
    minRole: AppUserRole.VIEWEDIT,
  },
  {
    id: ClientOnboardingTaskId.SetupGlobalFilters,
    title: "Setup Global Filters",
    description:
      "Want to exclude certain creatives, campaigns, etc. from your reports? Define global filters here.",
    categoryId: OnboardingCategoryId.SetupMetrics,
    importance: 2,
    actionName: "Setup Target Metrics",
    icon: FunnelIcon,
    minRole: AppUserRole.VIEWEDIT,
  },
  {
    id: ClientOnboardingTaskId.AssignUsers,
    title: "Invite & Assign Team Members",
    description:
      "Invite and assign all team members who should have access to this client.",
    categoryId: OnboardingCategoryId.AssignUsers,
    importance: 8,
    actionName: "Assign Users",
    icon: UsersIcon,
    minRole: AppUserRole.ADMIN,
  },
  {
    id: WorkspaceOnboardingTaskId.InviteTeam,
    title: "Invite Team",
    description:
      "Invite your team members to join this workspace and collaborate on projects.",
    categoryId: OnboardingCategoryId.InviteTeam,
    importance: 8,
    actionName: "Invite Team",
    icon: UserGroupIcon,
    minRole: AppUserRole.ADMIN,
  },
  {
    id: WorkspaceOnboardingTaskId.BookOnboarding,
    title: "Invite Team",
    description:
      "Invite your team members to join this workspace and collaborate on projects.",
    categoryId: OnboardingCategoryId.BookOnboarding,
    importance: 16,
    actionName: "Invite Team",
    icon: CalendarDaysIcon,
    minRole: AppUserRole.ADMIN,
  },
  {
    id: WorkspaceOnboardingTaskId.JoinChannel,
    title: "Join Slack Channel",
    description: "Get access to a private slack channel for premium support.",
    categoryId: OnboardingCategoryId.JoinSlackChannel,
    importance: 2,
    actionName: "Join",
    images: ["/img/slack.png"],
    featureFlag: "support-slack-channel_2025-01-17",
    minRole: AppUserRole.ADMIN,
  },
];

export const providerToTask: Record<Provider, ClientOnboardingTaskId> = {
  [Provider.META]: ClientOnboardingTaskId.ConnectMeta,
  [Provider.TIKTOK]: ClientOnboardingTaskId.ConnectTikTok,
  [Provider.GOOGLE]: ClientOnboardingTaskId.ConnectGoogle,
  [Provider.ADTRIBUTE]: ClientOnboardingTaskId.ConnectThirdParty,
  [Provider.GOOGLE_SHEETS]: ClientOnboardingTaskId.ConnectThirdParty,
  [Provider.HYROS]: ClientOnboardingTaskId.ConnectThirdParty,
  [Provider.TRIPLEWHALE]: ClientOnboardingTaskId.ConnectThirdParty,
  [Provider.TRACIFY]: ClientOnboardingTaskId.ConnectThirdParty,
};
